
.home_remove {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color:rgb(42,42,42);
}

.container_remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .7rem;
    width: 65rem;
    height: 30rem;
    background-color: rgb(42,42,42);
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
}

.container_box_remove{
    width: 31rem;
    height: 27rem;
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container_box_element_remove{
    width: 31rem;
    height: 25rem;
    background-color: rgb(200, 200, 200);
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container_box_element_remove img {
    width: 100%;
    height: 100%;
    border: solid 2px;
    border-radius: 1rem;
}

.container_box_button_remove {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    width: 15rem;
    height: 2.5rem;
    padding-top: 1.8rem;
}

.input_box_remove{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(42,42,42);
    border: none;
}


.input_box_remove input[type="file" i]::-webkit-file-upload-button{
    margin-left: 2rem;
    width: 8rem;
    height: 52px;
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    cursor: pointer;
    font-size: 0.9rem;

}

.button_box_remove {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 1rem;
    width: 10rem;
    height: 52px;
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    cursor: pointer;
}

.text_remove {
    color: #fff;
    font-size: 1.5rem;
}

.icon_download_remove{
    scale: 2;
}

.text_remove {
    color: #fff;
    font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {

    .home_remove{
        justify-content: start;
        padding-top: 3rem;
    }

    .container_remove{
        position: absolute;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        scale: 0.7;
        top: 1rem
    }

    .container_box_remove{
        padding: 3rem;
    }

    .input_box_remove{
        margin-left: 4rem;
    }

} 

@media only screen and (max-width: 1024px) {

    .home_remove{
        justify-content: start;
        padding-top: 3rem;
        height: 113vh;
    }

    .container_remove{
        position: absolute;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        scale: 0.7;
        top: 1rem;
    }

    .container_box_remove{
        padding: 3rem;
    }

    .input_box_remove{
        margin-left: 4rem;
    }

} 

