
.welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-color:rgb(42,42,42);
}

.buttonSend_welcome{
    width: 16.5rem;
    height: 3rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: #3d4f7c;
    border-width: 1px;
    border-radius: 2rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-family: sans-serif;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}
