
.home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color:rgb(42,42,42);
}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .7rem;
    width: 65rem;
    height: 30rem;
    background-color: rgb(42,42,42);
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
}

.container_box{
    width: 31rem;
    height: 27rem;
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container_box_element{
    width: 31rem;
    height: 25rem;
    background-color: rgb(200, 200, 200);
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container_box_element img {
    width: 100%;
    height: 100%;
    border: solid 2px;
    border-radius: 1rem;
}

.container_box_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 2.5rem;
    padding-top: 1.8rem;
}

.input_box{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(42,42,42);
    border: none;
    padding-bottom: 0.8rem;
}


.input_box input[type="file" i]::-webkit-file-upload-button{
    margin-left: 4.6rem;
    width: 7rem;
    height: 2.8rem;
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    cursor: pointer;
    font-size: 0.9rem;

}

.button_box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 1rem;
    width: 10rem;
    height: 52px;
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    cursor: pointer;
}

.text {
    color: #fff;
    font-size: 1.5rem;
}

.icon_download{
    scale: 2;
}

.text {
    color: #fff;
    font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {

    .home{
        justify-content: start;
        padding-top: 3rem;
    }

    .container{
        position: absolute;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        scale: 0.7;
        top: 1rem
    }

    .container_box{
        padding: 3rem;
    }

    .input_box{
        margin-left: 4rem;
    }

} 

@media only screen and (max-width: 1024px) {

    .home{
        justify-content: start;
        padding-top: 3rem;
        height: 113vh;
    }

    .container{
        position: absolute;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        scale: 0.7;
        top: 1rem;
    }

    .container_box{
        padding: 3rem;
    }

    .input_box{
        margin-left: 4rem;
    }

} 




.inputCard{
    position: relative;
    width: 18rem;
    height: 2.5rem;
    padding-left: 1rem;
    margin-top: 0.7rem;
    background: #3d4f7c;
    flex-direction: column;
    border-radius: 10px;
    border-width: 1px;
    border-color: #3d4f7c;
    border-style: solid;
    color: white;
    
}

.formClass{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 13rem;
    color: #fff;
    font-size: small;
}


.formClass h1{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}



.buttonSend{
    width: 18rem;
    height: 2.5rem;
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: #3d4f7c;
    border-width: 1px;
    border-radius: 2rem;
    color: white;
    cursor: pointer;
}

.buttonSend:hover{
    background-color: #323e5e;
}

.buttonClear{
    width: 18rem;
    height: 2.5rem;
    margin-top: 0.5rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: #3d4f7c;
    border-width: 1px;
    border-radius: 2rem;
    color: white;
}

.buttonClear:hover{
    background-color: #323e5e;
}

.errmsg{
    width: 10rem;
    height: 2.5rem;
    color: #fff;
}

.chooseFile{
    position: relative;
    width: 18rem;
    height: 2.5rem;
    padding-left: 5.5rem;
    margin-top: 1rem;
    color: white;

}


input[type=file]::file-selector-button {
    border-color: #3d4f7c;
    border-width: 1px;
    border-radius: 2rem;
    padding: .2em .4em;
    width: 8rem;
    height: 2.5rem;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.05);
    opacity: 0.7;
    cursor: pointer;
    
}

input[type='file'] {
    color: transparent;
}

input[type=file]::file-selector-button:hover {
    background-color: #3d4f7c;
}

  

.blue-glassmorphism {
    background: rgb(39, 51, 89, 0.4);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
}
  
.white-glassmorphism {
    padding-top: 0.6rem;    
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
  
