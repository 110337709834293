
.workspace {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color:rgb(42,42,42);
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 2rem;
    padding-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-right: 3rem;
    width: 42.5rem;
    height: 16rem;
    background-color: rgb(42, 42, 42);
    border: solid 2px;
    border-color: rgb(42,42,42);
    border-radius: 1rem;
    color: #fff;
}

.container_box_elementx{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 8rem;
}

.container_box_buttonx {
    width: 36rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonSend{
    width: 16.5rem;
    height: 3rem;
    margin-top: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: #3d4f7c;
    border-width: 1px;
    border-radius: 2rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-family: sans-serif;
    font-weight: 400;
}

.backsend{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14rem;
    height: 3rem;
    margin-top: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: #3d4f7c;
    border-width: 1px;
    border-radius: 2rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-family: sans-serif;
    font-weight: 400;
}

a:link {
    text-decoration:none;
}

a:hover{
    background-color: #323e5e;
}

.progress{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-wrap: wrap;
    width: 37.5rem;
}

.progressCirc{
    margin-top: 2rem;
}

.pProgress{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 1rem;
}

.queryBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-wrap: wrap;
}

.query{
    text-wrap: wrap;
}

.queryResponse{
    margin-top: 2rem;
    text-wrap: wrap;
}

.inputCardx{
    position: relative;
    width: 45rem;
    height: 3rem;
    padding-left: 1rem;
    margin-right: 3rem;
    margin-bottom: 1rem;
    background: #3d4f7c;
    flex-direction: column;
    border-radius: 10px;
    border-width: 1px;
    border-color: #3d4f7c;
    border-style: solid;
    color: rgb(236, 235, 235);
    font-size: 1rem;
    font-family: sans-serif;
    font-weight: 400;
    letter-spacing: 1.1px;
      
}

.blue-glassmorphism {
    background: rgb(39, 51, 89, 0.4);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
}
  
.white-glassmorphismx {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 32px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    
}
  